import { useState } from "react";
import arrow from "../../assets/images/arrow_down.png";

const Navbar = ({
  setSelectedTimeZone,
  showOptions,
  setShowOptions,
  setSName,
}) => {
  const [selectedOptn, setSelectedOptn] = useState(
    "Eastern Standard Time"
  );
  const timeZones = [
    {
      label: "Eastern Standard Time",
      value: "America/New_York",
      shortName: "EST",
    },
    {
      label: "Central Standard Time",
      value: "America/Chicago",
      shortName: "CST",
    },
    {
      label: "Mountain Standard Time",
      value: "America/Denver",
      shortName: "MST",
    },
    {
      label: "Pacific Standard Time",
      value: "America/Los_Angeles",
      shortName: "PST",
    },
  ];

  const handleButtonClick = (e) => {
    e.stopPropagation();
    setShowOptions((prev) => !prev);
  };

  const setZone = (zone) => {
    setSelectedTimeZone(zone.value);
    setSelectedOptn(zone.label);
    setSName(zone.shortName);
  };

  return (
    <nav className="flex flex-wrap items-center justify-between gap-5 py-20 nav-i-con mx-auto">
      <div className="">
        <p className="fs-24">watchitnow.today</p>
      </div>
      <div className="relative option-conatiner">
        <img src={arrow} alt="arrow" className="selectArrow" />
        <button
          onClick={handleButtonClick}
          style={{ opacity: showOptions ? 0 : 1 }}
        >
          {selectedOptn}
        </button>
        {showOptions && (
          <div className="options relative flex flex-column">
            {timeZones.map((zone, idx) => (
              <button
                key={zone.value}
                className="relative"
                onClick={() => setZone(zone)}
              >
                {zone.label}
                {idx !== timeZones.length - 1 && (
                  <hr style={{ width: "105%", marginTop: "4px" }} />
                )}
              </button>
            ))}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
