import { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./components/navbar";
import Table from "./components/table/inde";
import Loader from "./components/loader";

function App() {
  const [loader, setLoader] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("America/New_York");
  const [sName, setSName] = useState("EST");
  const fetchData = async () => {
    try {
      await axios.get('/api/games').then((res) => {
        if (res.status === 200) {
          setTableData(res.data);
          setLoader(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="px-10" onClick={() => setShowOptions(false)}>
      <Navbar
        setSelectedTimeZone={setSelectedTimeZone}
        showOptions={showOptions}
        setShowOptions={setShowOptions}
        setSName={setSName}
      />
      {loader ? (
        <Loader />
      ) : (
        <div className="table-container mx-auto">
          {tableData.map((data, idx) => (
            <Table
              key={idx}
              tHeading={data.date}
              tRow={data.events}
              selectedTimeZone={selectedTimeZone}
              sName={sName}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default App;
