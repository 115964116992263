import moment from "moment-timezone";
import "../../channels.css";

const classExists = (className) => {
  const stylesheets = Array.from(document.styleSheets);
  const classList = className.split(" ");

  for (const sheet of stylesheets) {
    if (!sheet.href || sheet.href.startsWith(window.location.origin)) {
      try {
        const rules = Array.from(sheet.cssRules || []);

        const classExists = classList.some((cls) =>
          rules.some((rule) => rule.selectorText === `.${cls}`)
        );

        if (classExists) {
          return true;
        }
      } catch (e) {
        console.warn(`Could not access stylesheet: ${sheet.href}`, e);
      }
    }
  }
  return false;
};

const timezoneCorrections = {
  "Asia/Calcutta": "Asia/Kolkata",
  // Add more mappings as needed
};

const getCorrectedTimezone = (detectedTimezone) => {
  return timezoneCorrections[detectedTimezone] || detectedTimezone;
};

const Table = ({ tHeading, tRow, selectedTimeZone, sName }) => {
  // const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTimezone = "UTC";
  const correctedTimezone = getCorrectedTimezone(localTimezone);

  return (
    <div className="overflow-auto">
      <table className="mb-20">
        <thead>
          <tr>
            <th>{tHeading}</th>
          </tr>
          <tr className="table-heading-two">
            <th>Game</th>
            <th>Time {sName}</th>
            <th>TV Channel (Stream)</th>
          </tr>
        </thead>
        <tbody>
          {tRow.map((data, index) => {
            const combinedDateTime = `${tHeading} ${data.time}`;
            const formattedTime = moment
              .tz(combinedDateTime, "ddd, MMM D h:mm A", correctedTimezone)
              .tz(selectedTimeZone)
              .format("h:mm A");

            const channelElements = data.channels
              .map((channel_details, idx) => {
                if (classExists(channel_details.logoClass)) {
                  return (
                    <a
                      href={channel_details.link}
                      target="_blank"
                      rel="noreferrer"
                      key={idx}
                      className="channel-link flex items-center gap-5"
                    >
                      {/* <div className={channel_details.logoClass}> */}
                        <img
                          src={'/'+channel_details.logoClass.split(' ')[0]+'.png'}
                          alt={channel_details.title}
                          width={"80"}
                          className="channel-logo"
                        />
                      {/* </div> */}
                      <span>{channel_details.title}</span>
                    </a>
                  );
                }
                return null;
              })
              .filter(Boolean);

            if (channelElements.length > 0) {
              return (
                <tr key={index}>
                  <td>{`${data.homeTeam} vs ${data.awayTeam}`}</td>
                  <td>
                    {formattedTime} {sName}
                  </td>
                  <td>
                    <div className="flex flex-wrap items-center justify-center gap-5">
                      <div className="flex flex-column gap-5">
                        {channelElements}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            }
            return null;
          })}
        </tbody>
      </table>
      {/* <div className="w-full sm-d-none">
        <p className="f-larger fw-600">{tHeading}</p>
        <div>
          {tRow.map((data, index) => {
            const combinedDateTime = `${tHeading} ${data.time}`;
            const formattedTime = moment
              .tz(combinedDateTime, "ddd, MMM D h:mm A", correctedTimezone)
              .tz(selectedTimeZone)
              .format("h:mm A");

            const channelElements = data.channels
              .map((channel_details, idx) => {
                if (classExists(channel_details.logoClass)) {
                  return (
                    <a
                      href={channel_details.link}
                      target="_blank"
                      rel="noreferrer"
                      key={idx}
                    >
                      <div className={channel_details.logoClass}></div>
                    </a>
                  );
                }
                return null;
              })
              .filter(Boolean);

            if (channelElements.length > 0) {
              return (
                <div
                  className="py-5 fs-20 dflex light-black fw-500"
                  key={index}
                >
                  <p className="my-5 p1">{`${data.homeTeam} vs ${data.awayTeam}`}</p>
                  <p className="my-5">
                    {formattedTime} {sName}
                  </p>

                  <div className="flex flex-wrap items-center gap-5">
                    {channelElements}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div> */}
    </div>
  );
};

export default Table;
